import { path, map, toPairs, filter } from 'ramda'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from '../../react-redux'
import { tokens, installSessionScripts } from '../analytics/track'
import { updateClientToken } from '../auth/actions/update_client_token'
import { updateCSRF } from '../auth/actions/update_csrf'
import callAPI, { URLType } from './call'

export type APIHookResponseMeta = {
  url: string
  status: number
  headers: Headers
  redirected: boolean
}

export type APIHookResponse<T> = {
  loading: boolean
  data?: T
  error?: Error
  response?: APIHookResponseMeta
  mutate?: (T) => void
  refresh?: () => void
}

export type ResultsList<T> = {
  page: number
  pages: number
  total: number
  results: T[]
}

export type ResourceResponse<T> = {
  success: boolean
  response: T
}

type RequestFunction = (
  method: string,
  url: URLType,
  headers?: any,
  json?: any
) => Promise<any>

const handleCommonResponseData = (res, auth, dispatch) => {
  if (res.payload) {
    const currentTokens = auth || {}
    if (res.payload.session_scripts) {
      installSessionScripts(res.payload.session_scripts)
    }
    if (res.payload.csrf_token && auth.csrf_token !== res.payload.csrf_token) {
      currentTokens.csrf_token = res.payload.csrf_token
      dispatch(updateCSRF(res.payload.csrf_token))
    }
    if (
      res.payload.client_token &&
      auth.client_token !== res.payload.client_token
    ) {
      currentTokens.client_token = res.payload.client_token
      dispatch(updateClientToken(res.payload.client_token))
    }
    tokens(currentTokens)
  }
  return res
}

export const useRequest = (): RequestFunction => {
  const auth = useSelector(path(['auth']))
  const dispatch = useDispatch()
  return (
    method: string,
    url: URLType,
    headers?: any,
    json?: any
  ): Promise<any> =>
    callAPI({
      method,
      url,
      headers,
      json,
    }).then(res => handleCommonResponseData(res, auth, dispatch))
}

const getRequestFunction = (): RequestFunction => {
  return (
    method: string,
    url: URLType,
    headers?: any,
    json?: any
  ): Promise<any> =>
    callAPI(
      {
        method,
        url,
        headers,
        json,
      },
      typeof window === 'undefined'
    )
}

export const useAuthenticatedRequest = (): RequestFunction => {
  const auth = useSelector(path(['auth']))
  const dispatch = useDispatch()
  return auth && auth['jwt']
    ? (method: string, url: URLType, headers?: any, json?: any): Promise<any> =>
        callAPI({
          method,
          url,
          headers: headers?.cookie
            ? headers
            : {
                ...headers,
                authorization: `Bearer ${auth['jwt']}`,
              },
          json,
        }).then(res => handleCommonResponseData(res, auth, dispatch))
    : undefined
}

const runAPI = <T>(
  method: string,
  url: string | { hostName: string; path: string },
  api?: RequestFunction,
  headers?: any,
  json?: any
): Promise<APIHookResponse<T>> => {
  return api(method, url, headers, json)
    .then(x => {
      return {
        error: undefined,
        loading: false,
        data: x.payload,
        response: x.response,
      }
    })
    .catch(x => {
      return {
        error: new Error(x.payload?.error ?? 'Something went wrong'),
        loading: false,
        data: x.payload,
        response: x.response,
      }
    })
}

const useAPI = <T>(
  method: string,
  url: string | { hostName: string; path: string },
  api?: RequestFunction
): APIHookResponse<T> => {
  const [refresh, setRefresh] = useState(0)

  const [response, setResponse] = useState({
    loading: true,
  } as APIHookResponse<T>)

  const mutate = (newResponse: T) =>
    setResponse({
      error: undefined,
      loading: false,
      data: newResponse,
      response: response.response,
      mutate,
      refresh: () => setRefresh(refresh + 1),
    })

  useEffect(() => {
    setResponse({ ...response, loading: true })

    if (api) {
      api(method, url)
        .then(x => {
          setResponse({
            error: undefined,
            loading: false,
            data: x.payload,
            response: x.response,
            mutate,
            refresh: () => setRefresh(refresh + 1),
          })
        })
        .catch(x => {
          setResponse({
            error: new Error(x.payload?.error ?? 'Something went wrong'),
            loading: false,
            data: x.payload,
            response: x.response,
            mutate,
            refresh: () => setRefresh(refresh + 1),
          })
        })
    }
  }, [method, url, !!api])

  return response
}

export const runGetRequest = <T>(
  url: URLType,
  headers?: any
): Promise<APIHookResponse<T>> => {
  const api = getRequestFunction()
  return runAPI('GET', url, api, headers)
}

export const runPostRequest = <T>(
  url: URLType,
  json: any,
  headers?: any
): Promise<APIHookResponse<T>> => {
  const api = getRequestFunction()
  return runAPI('POST', url, api, headers, json)
}

export const runAuthenticatedGetRequest = <T>(
  url: URLType,
  headers?: any
): Promise<APIHookResponse<T>> => {
  // TODO: make this actually make authenticated requests (needs jwt passing in)
  const api = getRequestFunction()
  return runAPI('GET', url, api, headers)
}

export const useGetRequest = <T>(url: URLType): APIHookResponse<T> => {
  const api = useRequest()
  return useAPI('GET', url, api)
}

export const useAuthenticatedGetRequest = <T>(
  url: URLType
): APIHookResponse<T> => {
  const api = useAuthenticatedRequest()
  return useAPI('GET', url, api)
}

export type PaginatedResults<T> = Partial<
  ResultsList<T> & {
    nextPage: () => void
    loading: boolean
    error: Error
  }
>

export const usePaginatedResults = function <T>(
  url: string,
  pageSize: number,
  params?: object,
  search: { [k: string]: any } = {}
): PaginatedResults<T> {
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const [total, setTotal] = useState(0)
  const [results, setResults] = useState([] as T[])
  const searchString = JSON.stringify(search)

  const queryString = map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
    filter(([k, v]) => v !== undefined, toPairs(params))
  ).join('&')

  const { data, loading, error } = useGetRequest<ResultsList<T>>(
    `${url}?per_page=${pageSize}&page=${page}` +
      (queryString ? `&${queryString}` : '') +
      `&q=${searchString}`
  )

  useEffect(() => {
    if (!error && !loading && page === data.page) {
      setResults([...results, ...data.results])
      setPages(data.pages)
      setTotal(data.total)
    }
  }, [page, url, pageSize, data?.page])

  if (error) {
    return { error }
  }
  const nextPage = () => setPage(page + 1)
  return { loading, results, page, pages, total, nextPage }
}
