import {
  LearningSeriesType,
  LearningSeriesWithModulesType,
  LearningModuleType,
  LearningModuleWithSessionType,
} from './types'

export type SeriesURLParts = {
  id: string
  slug: string
  library_id: string
  library?: { slug: string }
  payments_enabled: boolean
  has_subscription?: boolean
  accreditation_when: string
  has_passed_series?: boolean
}

export const getLibraryURL = (group): string => {
  if (typeof group.library_id !== 'undefined') {
    return `/learning/groups/${(group as LearningSeriesType).library_id}`
  }
  return `/learning/groups/${group.id}`
}

export const getSeriesURL = (series: {
  library_id: string
  id: string
}): string => {
  return getSeriesURLWithIDs(series.library_id, series.id)
}

export const getSeriesURLWithIDs = (
  library_id: string,
  series_id: string
): string => {
  return `/learning/groups/${library_id}/${series_id}`
}

export const getModuleURL = (module: {
  id: string
  slug?: string
  series?: SeriesURLParts
}): string => {
  if (!module.series) {
    throw new Error(`No series on module ${module.id}`)
    return '/learning'
  }

  return `/learning/groups/${
    module.series.library?.slug ?? module.series.library_id
  }/${module.series.slug}/${module.slug ?? module.id}`
}

export const getModuleURLWithRevision = (
  series: SeriesURLParts,
  revision: {
    id: string
    module_id: string
    module: { slug?: string }
  }
): string => {
  if (!series) {
    throw new Error(`No series getting links to module ${revision.module_id}`)
    return '/learning'
  }

  return `/learning/groups/${series.library?.slug ?? series.library_id}/${
    series.slug
  }/${revision.module?.slug ?? revision.module_id}`
}
