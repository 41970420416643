import { ApolloError, useQuery } from '@apollo/client'

import getConfig from '@/config'
import {
  chain,
  path,
  either,
  isNil,
  isEmpty,
  map,
  pipe,
  pluck,
  prop,
  reject,
  uniq,
} from 'ramda'

import { isNativeiOSAppBrowser } from '../../lib/client-info'
import * as track from '../../modules/analytics/track'
import {
  runAuthenticatedGetRequest,
  runGetRequest,
  useAuthenticatedGetRequest,
  useGetRequest,
} from '../../modules/api/hooks'
import { APIHookResponse } from '../../modules/api/types'

import {
  ActiveLearnerSessionDocument,
  LearnerRatingQuestionsFragment,
  LearnerSessionsForModuleDocument,
  LibraryContentQuery,
  ModuleResponseAndCompletionFragment,
  ModulesListForSeriesCompletionDocument,
  ModulesListForSeriesPassDocument,
  SeriesWithModulesFragment,
  UpdateLearnerModuleRatingsMutation,
  useActiveLearnerSessionQuery,
  useLastCompletedLearnerSessionQuery,
  useLastCompletedLearnerSessionForSeriesQuery,
  useLearnerModuleRatingQuestionsQuery,
  useLearnerModuleRatingResponsesQuery,
  useLearnerModuleReflectionsQuery,
  useLearnerSessionsForModuleQuery,
  useLogLearnerSessionAnswerMutation,
  useLogLearnerSessionFinishMutation,
  useLogLearnerSessionStartMutation,
  useMyLearningQuery,
  useUpdateLearnerModuleRatingsMutation,
  useUpdateLearnerModuleReflectionsMutation,
  useUpdateLearnerModuleStarRatingMutation,
  AuthorsForFilterDocument,
} from '../operations'
import {
  LearningModuleType,
  LearningModuleWithSessionType,
  LearningSeriesType,
  LearningSeriesWithModulesType,
  LearnerModuleReflections,
} from './types'
import { McqSettingsPassMarkType } from './index'

const siteConfig = getConfig().publicRuntimeConfig

export const reportGraphQLError = (e?: ApolloError) => {
  if (e && e.graphQLErrors) {
    console.error(JSON.stringify(e.graphQLErrors, null, 2))
  }
}

export const useLearningLibrary = (
  libraryType: string,
  libraryID: string
): { data?: LibraryContentQuery; loading?: boolean; error?: Error } => {
  const disallow = useAreSubscriptionsDisallowed()
  return useAuthenticatedGetRequest(
    `/api/cms/learning/${libraryType}/${libraryID}`
  )
}

export const usePublicLearningLibrary = (
  libraryID: string
): { data?: LibraryContentQuery; loading?: boolean; error?: Error } => {
  const disallow = useAreSubscriptionsDisallowed()
  return useGetRequest(`/api/cms/learning/group/${libraryID}`)
}

export const getPublicLearningLibrary = (
  libraryID: string
): Promise<{
  data?: LibraryContentQuery
  loading?: boolean
  error?: Error
}> => {
  const disallow = useAreSubscriptionsDisallowed()
  return runGetRequest(`/api/cms/learning/group/${libraryID}`)
}

export const useAreSubscriptionsDisallowed = () => {
  const isIOS = isNativeiOSAppBrowser()
  return isIOS
}

const useDisallowSubscriptions = () => {
  const disallow = useAreSubscriptionsDisallowed()
  return disallow ? { _eq: false } : {}
}

const UUID_REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export const useSeries = (
  libraryType: string,
  libraryID: string,
  seriesID: string,
  userID?: string
): {
  data?: LearningSeriesWithModulesType
  loading?: boolean
  error?: Error
} => {
  const disallow = useAreSubscriptionsDisallowed()
  return userID
    ? useAuthenticatedGetRequest(
        `/api/cms/learning/${libraryType}/${libraryID}/${seriesID}?disallowSubscriptions=${
          disallow ? '1' : ''
        }`
      )
    : useGetRequest(
        `/api/cms/learning/${libraryType}/${libraryID}/${seriesID}?disallowSubscriptions=${
          disallow ? '1' : ''
        }`
      )
}

export const useSeriesByID = (
  seriesID: string,
  userID?: string
): {
  data?: LearningSeriesWithModulesType
  loading?: boolean
  error?: Error
} => {
  const disallow = useAreSubscriptionsDisallowed()
  return userID
    ? useAuthenticatedGetRequest(
        `/api/cms/learning/series/${seriesID}?disallowSubscriptions=${
          disallow ? '1' : ''
        }`
      )
    : useGetRequest(
        `/api/cms/learning/series/${seriesID}?disallowSubscriptions=${
          disallow ? '1' : ''
        }`
      )
}

export const getSeries = (
  libraryType: string,
  libraryID: string,
  seriesID: string,
  userID?: string,
  headers?: any
): Promise<{
  data?: SeriesWithModulesFragment
  loading?: boolean
  error?: Error
}> => {
  const disallow = useAreSubscriptionsDisallowed()
  return userID
    ? runAuthenticatedGetRequest(
        `/api/cms/learning/${libraryType}/${libraryID}/${seriesID}?disallowSubscriptions=${
          disallow ? '1' : ''
        }`,
        headers
      )
    : runGetRequest(
        `/api/cms/learning/${libraryType}/${libraryID}/${seriesID}?disallowSubscriptions=${
          disallow ? '1' : ''
        }`,
        headers
      )
}

const getModuleWithQueries = async function (
  preview: 'draft' | 'published' | 'learning_published',
  libraryType: string,
  libraryID: string,
  moduleID: string,
  seriesID: string,
  vars: any,
  headers?: any
): Promise<
  APIHookResponse<ModuleResponseAndCompletionFragment> & {
    exists?: boolean
  }
> {
  const disallow = useAreSubscriptionsDisallowed()
  const use = vars.userID ? runAuthenticatedGetRequest : runGetRequest
  const {
    data: moduleData,
    ...rest
  }: APIHookResponse<ModuleResponseAndCompletionFragment> = await use(
    `/api/cms/learning/${libraryType}/${libraryID}/${seriesID}/${moduleID}?disallowSubscriptions=${
      disallow ? '1' : ''
    }${preview === 'draft' ? '&preview=1' : ''}`,
    headers
  )
  if (moduleData) {
    return {
      data: moduleData.status ? moduleData : undefined,
      exists: rest.response.status !== 404,
      ...rest,
    }
  }
  return rest
}

const useModuleWithQueries = function (
  preview: 'draft' | 'published' | 'learning_published',
  libraryType: string,
  libraryID: string,
  moduleID: string,
  seriesID: string,
  vars: any
): APIHookResponse<LearningModuleWithSessionType> & {
  exists?: boolean
} {
  const disallow = useAreSubscriptionsDisallowed()
  const use = vars.userID ? useAuthenticatedGetRequest : useGetRequest

  const {
    data: moduleData,
    ...rest
  }: APIHookResponse<LearningModuleWithSessionType> = use(
    `/api/cms/learning/${libraryType}/${libraryID}/${seriesID}/${moduleID}?disallowSubscriptions=${
      disallow ? '1' : ''
    }${preview === 'draft' ? '&preview=1' : ''}`
  )
  if (moduleData) {
    return {
      data: moduleData.status ? moduleData : undefined,
      exists: rest.response.status !== 404,
      ...rest,
    }
  }
  return rest
}

export const getModule = (
  libraryType: string,
  libraryID: string,
  moduleID: string,
  seriesID: string,
  currentUser?: { id: string },
  headers?: any
) => {
  return getModuleWithQueries(
    'published',
    libraryType,
    libraryID,
    moduleID,
    seriesID,
    {
      userID: currentUser ? parseInt(currentUser.id) : undefined,
    },
    headers
  )
}

export const useModule = (
  libraryType: string,
  libraryID: string,
  moduleID: string,
  seriesID: string,
  currentUser?: { id: string }
) => {
  return useModuleWithQueries(
    'published',
    libraryType,
    libraryID,
    moduleID,
    seriesID,
    {
      userID: currentUser ? parseInt(currentUser.id) : undefined,
    }
  )
}

export const useLearningModule = (
  libraryType: string,
  libraryID: string,
  moduleID: string,
  seriesID: string,
  currentUser: { id: string }
) => {
  return useModuleWithQueries(
    'learning_published',
    libraryType,
    libraryID,
    moduleID,
    seriesID,
    {
      userID: currentUser ? parseInt(currentUser.id) : undefined,
    }
  )
}

export const useDraftModule = (
  libraryType: string,
  libraryID: string,
  moduleID: string,
  seriesID: string,
  currentUser?: { id: string }
) => {
  return useModuleWithQueries(
    'draft',
    libraryType,
    libraryID,
    moduleID,
    seriesID,
    {
      userID: currentUser ? parseInt(currentUser.id) : undefined,
    }
  )
}

export type LearnerSessionAnswer = {
  id: string // uuid of answer
  selected?: boolean // whether user selected this answer
}

export type LearnerSessionAnswers = LearnerSessionAnswer[]

type LearnerSession = {
  id: string
  started_at: string
  marks: number
  marks_available: number
  active: boolean
}

type LearnerSessionFinished = LearnerSession & {
  finished_at: string
}

type LearnerSessionQuestionFeedback = {
  question_id: string
  feedback: string
  answers: LearnerSessionAnswerFeedback[]
}

type LearnerSessionAnswerFeedback = {
  answer_id: string
  selected: boolean
  answered_correctly: boolean
}

type LearnerSessionFeedback = LearnerSessionQuestionFeedback[]

const getMaybeMostRecentEvent = sessions => {
  if (!sessions) return null

  const mostRecentSession = sessions[sessions.length - 1]
  return mostRecentSession?.events[mostRecentSession?.events.length - 1]
}

export const useActiveLearnerSession = (moduleID: string, actorID: number) => {
  const { data, loading } = useActiveLearnerSessionQuery({
    variables: {
      actorID,
      moduleID,
    },
  })

  return {
    data: () => data?.learning_learner_sessions,
    loading: () => loading,
  }
}

export const useLastCompletedLearnerSession = (
  moduleID: string,
  actorID: number
) => {
  const { data, loading } = useLastCompletedLearnerSessionQuery({
    variables: {
      actorID,
      moduleID,
    },
    fetchPolicy: 'network-only',
  })

  return {
    data: data?.learning_learner_sessions,
    loading: loading,
  }
}

export const useLastCompletedLearnerSessionForSeries = (
  seriesID: string,
  actorID: number
) => {
  const { data, loading } = useLastCompletedLearnerSessionForSeriesQuery({
    variables: {
      actorID,
      seriesID,
    },
    fetchPolicy: 'network-only',
  })

  return {
    data: data?.learning_learner_sessions,
    loading: loading,
  }
}

export const useLearnerSession = (
  moduleID: string,
  seriesID: string,
  actorID: number
) => {
  const [start, startStatus] = useLogLearnerSessionStartMutation({
    refetchQueries: [
      {
        query: ActiveLearnerSessionDocument,
        variables: { moduleID, actorID },
      },
      {
        query: LearnerSessionsForModuleDocument,
        variables: { moduleID, actorID },
      },
    ],
    awaitRefetchQueries: true,
  })
  const [finish, finishStatus] = useLogLearnerSessionFinishMutation({
    refetchQueries: [
      {
        query: ActiveLearnerSessionDocument,
        variables: { moduleID, actorID },
      },
      {
        query: LearnerSessionsForModuleDocument,
        variables: { moduleID, actorID },
      },
    ],
    awaitRefetchQueries: true,
  })
  const [answer, answerStatus] = useLogLearnerSessionAnswerMutation()

  const updateSession = response => {
    const x = path(['data', 'event', 'session'], response)
    //setSession(x)
  }

  const {
    data: dataSessions,
    loading: loadingSessions,
    refetch: refetchSessions,
  } = useLearnerSessionsForModuleQuery({
    variables: {
      actorID,
      moduleID,
    },
    fetchPolicy: 'network-only',
  })

  const sessions = dataSessions?.learning_learner_sessions
  const session = sessions?.length
    ? getMaybeMostRecentEvent(sessions)?.session
    : undefined

  return {
    id: () => (dataSessions ? sessions.length : 0),
    loading: () =>
      answerStatus.loading || startStatus.loading || finishStatus.loading,
    error: () => answerStatus.error || startStatus.error || finishStatus.error,
    // refetchSessions: () => {
    //   console.log('refetching sessions')
    //   return refetchSessions()
    // },
    loadingSessions: () => loadingSessions,
    sessions: () => sessions,
    session: () => session,
    start: () => {
      const maybeMostRecentEvent = getMaybeMostRecentEvent(sessions)
      const isFirstAttempt = !maybeMostRecentEvent
      const isRetrying =
        sessions?.length > 0 && !maybeMostRecentEvent?.session.active
      if (isFirstAttempt || isRetrying) {
        return start({ variables: { moduleID, actorID } }).then(s => {
          track.event('Learning Module', 'Session Start', {
            label: moduleID,
            'Content ID': moduleID,
            'Content Title': s.data.event.session.revision?.title,
          })
          updateSession(s)
          return s
        })
      }
      return Promise.resolve(true)
    },
    answer: (
      pageID: string,
      questionID: string,
      answers: LearnerSessionAnswers
    ) =>
      answer({ variables: { moduleID, pageID, questionID, answers } }).then(
        s => {
          track.event('Learning Module', 'Session Answer', {
            label: moduleID,
            'Content ID': moduleID,
            'Content Title': s.data.event.session.revision?.title,
            item_uid: questionID,
            item_type: 'learning_question',
          })
          updateSession(s)
          return s
        }
      ),
    finish: async () =>
      await finish({ variables: { moduleID, actorID } }).then(s => {
        track.event('Learning Module', 'Session Finish', {
          label: moduleID,
          'Content ID': moduleID,
          'Content Title': s.data.event.session.revision?.title,
        })
        updateSession(s)
        refetchSessions().then(s => {
          return s
        })
        return s
      }),
  }
}

export type LearnerReflectionsEditor = Readonly<{
  loading: boolean
  saving: boolean
  error?: Error
  reflections: Readonly<LearnerModuleReflections>
  update: (reflections: { body: string }) => Promise<LearnerModuleReflections>
  refetch: () => void
}>

export const useLearnerReflectionsEditor = (
  moduleID: string,
  actorID: number,
  isPreview?: boolean
): LearnerReflectionsEditor => {
  const { data, loading, error, refetch } = useLearnerReflections(
    moduleID,
    actorID
  )
  const [update, updateStatus] = useUpdateLearnerModuleReflectionsMutation()

  reportGraphQLError(error)
  reportGraphQLError(updateStatus.error)

  return {
    loading,
    saving: updateStatus.loading,
    error: updateStatus.error ?? error,
    reflections: isPreview ? { body: '' } : data,
    refetch,
    update: reflections =>
      update({ variables: { moduleID, actorID, body: reflections.body } }).then(
        x => x.data.reflections
      ),
  }
}

export const useLearnerReflections = (
  moduleID: string,
  actorID: number
): {
  data?: LearnerModuleReflections
  loading: boolean
  error?: ApolloError
  refetch: () => void
} => {
  const { data, loading, error, refetch } = useLearnerModuleReflectionsQuery({
    variables: {
      moduleID,
      actorID,
    },
    fetchPolicy: 'network-only',
  })

  return { data: data ? data.reflections : undefined, loading, error, refetch }
}

type LearnerRatingsEditor = Readonly<{
  loading: boolean
  saving: boolean
  error?: Error
  questions: Readonly<LearnerRatingQuestionsFragment[]>
  responses: any // TODO: update this type
  update: (
    responses: {
      question_id: string
      response?: number
      response_text?: string
    }[]
  ) => Promise<UpdateLearnerModuleRatingsMutation>
}>

export const useLearnerRatingsEditor = (
  seriesID: string,
  moduleID: string,
  actorID: number,
  sessionID: string,
  isPreview?: boolean
): LearnerRatingsEditor => {
  const {
    data: questionsData,
    loading: questionsLoading,
    error: questionsError,
  } = useLearnerRatingsQuestions(seriesID, moduleID)

  const {
    data: responsesData,
    loading: responsesLoading,
    error: responsesError,
  } = useLearnerRatingsResponses(moduleID, actorID, sessionID)

  const [update, updateStatus] = useUpdateLearnerModuleRatingsMutation()

  reportGraphQLError(questionsError)
  reportGraphQLError(updateStatus.error)

  return {
    loading: questionsLoading,
    saving: updateStatus.loading,
    error: updateStatus.error ?? questionsError,
    questions: questionsData,
    responses: isPreview ? [] : responsesData,
    update: (
      responses: {
        question_id: string
        response?: number
        response_text?: string
      }[]
    ) =>
      update({ variables: { sessionID, moduleID, actorID, responses } }).then(
        x => x.data
      ),
  }
}

export const useLearnerRatingsQuestions = (
  seriesID: string,
  moduleID: string
): {
  data?: LearnerRatingQuestionsFragment[]
  loading: boolean
  error?: ApolloError
} => {
  const { data, loading, error } = useLearnerModuleRatingQuestionsQuery({
    variables: {
      seriesID,
      moduleID,
    },
  })

  return {
    data: data
      ? data.module_questions.length
        ? data.module_questions
        : data.series_questions.length
        ? data.series_questions
        : data.standard_questions
      : undefined,
    loading,
    error,
  }
}

const useLearnerRatingsResponses = (
  moduleID: string,
  userID: number,
  sessionID: string
) => {
  const { data, loading, error } = useLearnerModuleRatingResponsesQuery({
    variables: {
      moduleID,
      sessionID,
      userID,
    },
    fetchPolicy: 'network-only',
  })

  return {
    data: data?.learning_learner_module_rating_responses,
    loading,
    error,
  }
}

export const useLearnerStarRatingsEditor = () => {
  const [update, updateStatus] = useUpdateLearnerModuleStarRatingMutation()

  reportGraphQLError(updateStatus.error)

  return {
    update: ({ moduleID, rating }: { moduleID: string; rating: number }) =>
      update({
        variables: {
          moduleID,
          rating,
        },
      }).then(x => console.log(x)),
  }
}

export const PAGE_SIZE_MY_LEARNING = 200

export const useMyLearningModules = ({
  userID,
  page,
}: {
  userID: number
  page?: number
}) => {
  const { data, loading, error } = useMyLearningQuery({
    variables: {
      userID,
      limit: PAGE_SIZE_MY_LEARNING,
      offset: (page ?? 0) * PAGE_SIZE_MY_LEARNING,
    },
    fetchPolicy: 'network-only',
  })

  return {
    data,
    loading,
    error,
  }
}

export const useModulesInSeriesEverCompleted = module => {
  const currentModulePosition = module.position

  const { loading, error, data } = useQuery(
    ModulesListForSeriesCompletionDocument,
    {
      fetchPolicy: 'cache-and-network',
      variables: { seriesID: module.series_id },
    }
  )

  let incompleteModule = false
  let nextModule = null

  if (data?.modules) {
    const foundIncompleteModule = data.modules.find(m => {
      const isPastModule = m.position < currentModulePosition
      const isModuleIncomplete =
        module.revision?.mcq_settings_pass_mark_type ===
        McqSettingsPassMarkType.none
          ? !m.ever_completed
          : !m.has_passed

      return isPastModule && isModuleIncomplete
    })

    incompleteModule = !!foundIncompleteModule

    nextModule = data.modules.find(
      m => m.position === currentModulePosition + 1
    )
  }

  return {
    loading,
    error,
    previousModulesCompleted: !incompleteModule,
    incompleteModule,
    nextModule,
  }
}

export const useModulesInSeriesPassed = seriesID => {
  const { loading, error, data } = useQuery(ModulesListForSeriesPassDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { seriesID },
  })

  if (data?.modules) {
    const hasFail = !!data?.modules.find(m => !m.has_passed)

    return { loading: false, hasPassedAll: !hasFail }
  }

  return { loading, error }
}

const cleanNestedList = pipe(
  pluck('published_revision'),
  chain(prop('cover_author')),
  reject(either(isNil, isEmpty)),
  uniq,
  map(x => (typeof x === 'string' ? x.trim() : x))
)

const cleanNested = pipe(
  pluck('display_author'),
  reject(either(isNil, isEmpty)),
  uniq,
  map(x => (typeof x === 'string' ? x.trim() : x))
)

export const useAuthorsForFilter = () => {
  const { data, loading, error } = useQuery(AuthorsForFilterDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const moduleAuthors: string[] = cleanNestedList(data?.modules ?? [])
  const seriesAuthors: string[] = cleanNested(data?.series ?? [])
  const authors = [...moduleAuthors, ...seriesAuthors]

  return { loading: loading, authors, error }
}
