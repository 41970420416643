import {
  LearningSessionScore,
  LearningModuleRevisionScore,
  McqSettingsPassMarkType,
} from './types'

export const calculateScoreAndPassStatus = (
  session: LearningSessionScore,
  revision: LearningModuleRevisionScore
): { score: string; isPass: null | boolean } => {
  let score,
    isPass = null

  // safety check for valid session
  if (!session || !revision) return { score, isPass }

  if (
    revision.mcq_settings_pass_mark_type === McqSettingsPassMarkType.percentage
  ) {
    isPass =
      ((session.marks ?? 0) / session.marks_available) * 100 >=
      revision.mcq_settings_pass_mark
    score = `${Math.round(
      (100 * session.marks ?? 0) / session.marks_available
    )}%`
  } else if (
    revision.mcq_settings_pass_mark_type === McqSettingsPassMarkType.absolute
  ) {
    isPass = (session.marks ?? 0) >= revision.mcq_settings_pass_mark
    score = session.marks ?? 0 / session.marks_available
  } else if (
    revision.mcq_settings_pass_mark_type === McqSettingsPassMarkType.none
  ) {
    score = session.marks ?? 0 / session.marks_available
  }

  return { score, isPass }
}
